

import React, { useEffect, useRef, useState } from "react";
import Heading from "./Heading/Heading";
import CardCategory3 from "./CardCategories/CardCategory3";
// @ts-ignore
import Glide from "@glidejs/glide/dist/glide.esm";
import { CATS_DISCOVER } from "./CardCategories/data";
import { useTranslation } from "react-i18next";

const DiscoverMoreSlider = React.memo(() => {
  const sliderRef = useRef(null);
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const OPTIONS: Partial<Glide.Options> = {
      perView: 3.2,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          gap: 28,
          perView: 2.7,
        },
        1279: {
          gap: 20,
          perView: 2.35,
        },
        1023: {
          gap: 20,
          perView: 1.8,
        },
        768: {
          gap: 20,
          perView: 1.4,
        },
        500: {
          gap: 20,
          perView: 1.25,
        },
      },
    };

    if (!sliderRef.current) return;

    const slider = new Glide(sliderRef.current, OPTIONS);
    slider.mount();
    setIsShow(true);

    return () => {
      slider.destroy();
    };
  }, []);

  return (
    <div
      ref={sliderRef}
      className={`nc-DiscoverMoreSlider nc-p-l-container ${
        isShow ? "" : "invisible"
      }`}
    >
      <Heading
        className="mb-8 md:mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50 nc-p-r-container"
        desc=""
        rightDescText={t("handcraftedArt")}
        hasNextPrev
      >
        {t("printsWithPassion")}
      </Heading>
      <div className="" data-glide-el="track">
        <ul className="glide__slides">
          {CATS_DISCOVER.map((item, index) => (
            <li key={index} className={`glide__slide`}>
              <CardCategory3
                name={item.name}
                desc={item.desc}
                featuredImage={item.featuredImage}
                color={item.color}
                className={item.className}
                href={item.href}
                buttonText={item.buttonText}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export default DiscoverMoreSlider;
