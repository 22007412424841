export const resources = {
  "en": {
    "translation": {
      "language": "ENG",
      "Prints": "Prints",
      "Frames": "Frames",
      "Bestsellers": "Bestsellers",
      "Your Design": "Your Design",
      "Our Catalog": "Our Catalog",
      "About Us": "About Us",
      "Contact Us": "Contact Us",
      "Nature": "Nature",
      "Nature prints": "Nature prints",
      "Botanical art": "Botanical art",
      "Animal art": "Animal art",
      "Space and astronomy": "Space and astronomy",
      "Maps and cities": "Maps and cities",
      "Vintage & Retro": "Vintage & Retro",
      "Retro and vintage": "Retro and vintage",
      "Black and white": "Black and white",
      "Gold and silver": "Gold and silver",
      "Historical posters": "Historical posters",
      "Classic posters": "Classic posters",
      "Art Styles": "Art Styles",
      "Illustrations": "Illustrations",
      "Photographs": "Photographs",
      "Art prints": "Art prints",
      "Text posters": "Text posters",
      "Graphical": "Graphical",
      "Famous Painters": "Famous Painters",
      "Iconic photos": "Iconic photos",
      "Studio Collections": "Studio Collections",
      "Modern artists": "Modern artists",
      "Abstract art": "Abstract art",
      "printsWithPassion": "Prints with Passion",
      "handcraftedArt": "Handcrafted art, beautiful and unique",
      "Art Lovers Also Bought": "Art Lovers Also Bought",
      "Popular Picks for You": "Popular Picks for You",
      "From the Ciseco blog": "From the Ciseco blog",
      "The latest news": "The latest news",
      "Show all blog articles": "Show all blog articles",
      "Trending Art Pieces": "Trending Art Pieces",
      "All Items": "All Items",
      "Vintage and Retro": "Vintage and Retro",
      "Filter": "Filter",
      "Discover the most popular prints in our collection.": "Explore Our Collection to Discover the Most Popular Prints and Best-Selling Designs.",
      "Quick view": "Quick view",
      "Start exploring": "Start exploring",
      "See Prints": "See Prints",
      "products": "products",
      "Visit Us": "Visit Us",
      "in Den Bosch": "in Den Bosch",
      "Experience our craftsmanship firsthand. Pick up your prints, enjoy a coffee, and see where the magic happens": "Experience our craftsmanship firsthand. Pick up your prints, enjoy a coffee, and see where the magic happens",
      "Learn More": "Learn More",
      "Plan Your Visit": "Plan Your Visit",
      "Sort order": "Sort order",
      "Categories": "Categories",
      "Clear": "Clear",
      "Apply": "Apply",
      "Min price": "Min price",
      "Max price": "Max price",
      "Products filters": "Products filters",
      "On sale": "On sale",
      "Products currently on sale": "Products currently on sale",
      "Show me more": "Show me more",
      "Show me all": "Show me all",
      "Browse & Select": "Browse & Select",
      "Add to Cart": "Add to Cart",
      "Fast Shipping" : "Fast Shipping",
      "Enjoy Your Art": "Enjoy Your Art",
      "Shop Stunning Prints & Frames for Your Home Decor.": "Shop Stunning Prints & Frames for Your Home Decor.",
      "Custom Photo Prints & Framed Art": "Custom Photo Prints & Framed Art",
      "Learn About Giclée Printing": "Learn About Giclée Printing",
      "Upload, Customize, and Order Your Own Design.": "Upload, Customize, and Order Your Own Design.",
      "Superior Quality Prints with Archival Inks & Paper.": "Superior Quality Prints with Archival Inks & Paper.",
      "Affordable High-Quality Art Prints & Framed Wall Art": "Affordable High-Quality Art Prints & Framed Wall Art",
      "Easily explore our wide range of art prints and find your perfect piece": "Easily explore our wide range of art prints and find your perfect piece",
      "Choose your print, frame, and paper options, then add to your cart with a click": "Choose your print, frame, and paper options, then add to your cart with a click",
      "We carefully pack and ship your order within two days, ensuring it arrives quickly and safely": "We carefully pack and ship your order within two days, ensuring it arrives quickly and safely",
      "Unwrap and enjoy your beautifully crafted art print, made with love and care": "Unwrap and enjoy your beautifully crafted art print, made with love and care",
      "Expert Giclée Printing Service": "Expert Giclée Printing Service",
      "Explore Art Prints": "Explore Art Prints",
      "Create Your Custom Print": "Create Your Custom Print",
      "Quality You Can Feel": "Quality You Can Feel",
      "Diverse Art Styles": "Diverse Art Styles",
      "Visit Our Space": "Visit Our Space",
      "Exclusive Discounts": "Exclusive Discounts",
      "Step": "Step",
      "Choose Your Perfect Frame": "Choose Your Perfect Frame",
      "Explore our wide range of frames to find the perfect match for your paintings and photos. Our high-quality framing options ensure durability and style, enhancing the presentation of your artwork. Select from various styles and finishes to suit your preferences and decor.": "Explore our wide range of frames to find the perfect match for your paintings and photos. Our high-quality framing options ensure durability and style, enhancing the presentation of your artwork. Select from various styles and finishes to suit your preferences and decor.", 
      "Explore Our Exquisite Frame Collection": "Explore Our Exquisite Frame Collection",
      "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints": "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints",
      "Our Frames": "Our Frames",   
      "Technical Specifications": "Technical Specifications",
      "The walnut wood card tray is precision milled to perfectly fit a stack of Focus cards. The powder coated steel divider separates active cards from new ones, or can be used to archive important task lists.": "The walnut wood card tray is precision milled to perfectly fit a stack of Focus cards. The powder coated steel divider separates active cards from new ones, or can be used to archive important task lists.",
      "Material": "Material",
      "Premium wood base with a smooth matte finish.": "Premium wood base with a smooth matte finish.",
      "Dimensions": "Dimensions",
      "Available in multiple sizes to fit your artwork perfectly.": "Available in multiple sizes to fit your artwork perfectly.",
      "Finish": "Finish",
      "Hand-finished with a durable, scratch-resistant coating.": "Hand-finished with a durable, scratch-resistant coating.",
      "Includes": "Includes",
      "Frame, hanging hardware, and protective glass cover.": "Frame, hanging hardware, and protective glass cover.",
      "Considerations": "Considerations",
      "Made from natural materials. Grain and color may vary with each frame.": "Made from natural materials. Grain and color may vary with each frame.",
      "Final Stock.": "Final Stock.",
      "Up to 50% off.": "Up to 50% off.",
      "Go to sales": "Go to sales",
      "Welcome to PrintMeArt! We're a family-run business turning your favorite memories and artworks into stunning prints. Using top-notch Giclée printing, we ensure vibrant colors and fine detail in every piece. We believe in the magic of art to brighten your space. With a personal touch and genuine care, we offer a wide range of printing and framing options to suit your style.Join our PrintMeArt family and let us help you create something special. Your masterpiece awaits!" : "Welcome to PrintMeArt! We're a family-run business turning your favorite memories and artworks into stunning prints. Using top-notch Giclée printing, we ensure vibrant colors and fine detail in every piece. We believe in the magic of art to brighten your space. With a personal touch and genuine care, we offer a wide range of printing and framing options to suit your style.Join our PrintMeArt family and let us help you create something special. Your masterpiece awaits!",
      "About Us.": "About Us.",
      "Founders": "Founders",
      "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints." : "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints.",
      "Co-founder and Chief Executive": "Co-founder and Chief Executive",
      "Good news from far away": "Good news from far away",
      "Let's see what people think of PrintMe Art": "Let's see what people think of PrintMe Art",
      "Fast Facts": "Fast Facts",
      "We’re impartial and independent, and every day we create distinctive, world-class programmes and content": "We’re impartial and independent, and every day we create distinctive, world-class programmes and content", 
      "High-Quality Prints": "High-Quality Prints",
      "Crafted with care and top-notch Giclée technology": "Crafted with care and top-notch Giclée technology",
      "Engineers Turned Artists": "Engineers Turned Artists",
      "Family-owned business led by two passionate engineers": "Family-owned business led by two passionate engineers",
      "Custom Options": "Custom Options",
      "Choose from a variety of sizes, frames, and finishes": "Choose from a variety of sizes, frames, and finishes",
      "Don't miss out on special offers": "Don't miss out on special offers",
      "Register to receive news about our latest deals, exclusive offers, and discount codes!": "Register to receive news about our latest deals, exclusive offers, and discount codes!",
      "Free Shipping": "Free Shipping",
      "Premium Quality": "Premium Quality",
      "Enter your email": "Enter your email",
      "Navigation": "Navigation",
      "Nature & Art": "Nature & Art",
      "Experience our<br /> premium Giclée<br /> prints": "Experience our<br /> premium Giclée<br /> prints",
      "Vintage, <br />modern, nature,<br />abstract prints.": "Vintage, <br />modern, nature,<br />abstract prints.",
      "Pick up prints,<br />enjoy coffee.": "Pick up prints,<br />enjoy coffee.",
      "Up to <br /> 80% off selected art": "Up to <br /> 80% off selected art",
      "Type your keywords": "Type your keywords",
      "SOCIALS": "SOCIALS",
      "Full name": "Full name",
      "Email address": "Email address",
      "Message": "Message",
      "🗺 ADDRESS":"🗺 ADDRESS",
      "☎ PHONE": "☎ PHONE",
      "Example Doe": "Example Doe",
      "example@example.com": "example@example.com",
      "Send Message": "Send Message",
      "Log in": "Log in",
      "Shopping cart": "Shopping cart",
      "Subtotal": "Subtotal",
      "Shipping and taxes calculated at checkout.": "Shipping and taxes calculated at checkout.",
      "View cart": "View cart",
      "Check out": "Check out",
      "Shopping Cart": "Shopping Cart",
      "Clothing Categories": "Clothing Categories",
      "Homepage": "Homepage",
      "Order Summary": "Order Summary",
      "Shipping estimate": "Shipping estimate",
      "Tax estimate": "Tax estimate",
      "Confirm order": "Confirm order",
      "Order total": "Order total",
      "Discount code": "Discount code",
      "Learn more ": "Learn more ",
      "Taxes": "Taxes",
      " and ": " and ",
      "Shipping ": "Shipping ",
      "infomation": "infomation",
      "CONTACT INFO": "CONTACT INFO",
      "SHIPPING ADDRESS": "SHIPPING ADDRESS",
      "Update account": "Update account",
      "Change Image": "Change Image",
      "Account infomation": "Account infomation",
      "Change": "Change",
      "First name": "First name",
      "Last name": "Last name",
      "Apt, Suite *": "Apt, Suite *",
      "City": "City",
      "Country": "Country",
      "State/Province": "State/Province",
      "Postal code": "Postal code",
      "Address type": "Address type",
      "Home": "Home",
      "All Day Delivery": "All Day Delivery",
      "Office": "Office",
      "Delivery": "Delivery",
      "Save and next to Payment": "Save and next to Payment",
      "Cancel": "Cancel",
      "PAYMENT METHOD": "PAYMENT METHOD",
      "Google / Apple Wallet": "Google / Apple Wallet",
      "Type and press enter": "Type and press enter",
      "Landscapes": "Landscapes",
      "Movies & Games posters": "Movies & Games posters",
      "Music posters": "Music posters",
      "Sport posters": "Sport posters",
      "General posters": "General posters",
      "Kids' wall art": "Kids' wall art",
      "Dutch masters": "Dutch masters",
      "Modern masters": "Modern masters",
      "Nature & Landscapes": "Nature & Landscapes",
      "From": "From",
      "Turn Your Photos into Art": "Turn Your Photos into Art",
      "Upload your favorite image, customize your design, and let our designer ensure a stunning framed print, approved by you before we print.": "Upload your favorite image, customize your design, and let our designer ensure a stunning framed print, approved by you before we print.",
      "Upload Your Image": "Upload Your Image",
      "Simply upload your favorite photo or artwork. We accept PNG, JPG, and GIF files up to 10MB.": "Simply upload your favorite photo or artwork. We accept PNG, JPG, and GIF files up to 10MB.",
      "Customize Your Design": "Customize Your Design",
      "Choose from a variety of frames, sizes, and mats. Visualize your design instantly before finalizing your order.": "Choose from a variety of frames, sizes, and mats. Visualize your design instantly before finalizing your order.",
      "Professional Review": "Professional Review",
      "Our expert designer will review your design, make any necessary adjustments, and send it to you for approval before printing.": "Our expert designer will review your design, make any necessary adjustments, and send it to you for approval before printing.",
      "Order with Confidence": "Order with Confidence",
      "Complete your purchase with our secure checkout. Your custom art will be professionally printed and delivered to your door.": "Complete your purchase with our secure checkout. Your custom art will be professionally printed and delivered to your door.",
      "Upload a file": "Upload a file",
      "or drag and drop": "or drag and drop",
      "Selected file size": "Selected file size",
      "Total": "Total",
      "Include Mat": "Include Mat",
      "Image Size": "Image Size",
      "See sizing chart": "See sizing chart",
      "See frame details": "See frame details",
      "We Print & Frame": "We Print & Frame",
      "Gallery-Quality Art & Photography": "Gallery-Quality Art & Photography",
      "We Print": "We Print",
      "Elevate your home with museum-grade...": "Transform your home with museum-quality art prints and striking photography. Our carefully curated collection showcases vivid colors and fine details. Explore beautiful posters, elegant prints, and captivating photos, all with custom framing options to enhance any space at affordable prices.",
      "Discover our best-selling art prints, posters, and photos. Find top picks that art enthusiasts and decor lovers adore for every space": "Discover our best-selling art prints, posters, and photos. Find top picks that art enthusiasts and decor lovers adore for every space",
      "High-Quality Art Prints, Posters, Custom Framing, and Stunning Photography for Your Home": "High-Quality Art Prints, Posters, Custom Framing, and Stunning Photography for Your Home",
      "Print Quality & Care": "Print Quality & Care",
    }
  },
 "nl": {
    "translation": {
      "language": "NL",
      "Prints": "Afdrukken",
      "Frames": "Kaders",
      "Bestsellers": "Bestsellers",
      "Your Design": "Uw Ontwerp",
      "Our Catalog": "Onze Catalogus",
      "About Us": "Over Ons",
      "Contact Us": "Contacteer Ons",
      "Nature": "Natuur",
      "Nature prints": "Natuurafdrukken",
      "Botanical art": "Botanisch kunst",
      "Animal art": "Dieren kunst",
      "Space and astronomy": "Ruimte en astronomie",
      "Maps and cities": "Kaarten en steden",
      "Vintage & Retro": "Vintage & Retro",
      "Retro and vintage": "Retro en vintage",
      "Black and white": "Zwart en wit",
      "Gold and silver": "Goud en zilver",
      "Historical posters": "Historische posters",
      "Classic posters": "Klassieke posters",
      "Art Styles": "Kunststijlen",
      "Illustrations": "Illustraties",
      "Photographs": "Foto's",
      "Art prints": "Kunstafdrukken",
      "Text posters": "Tekstposters",
      "Graphical": "Grafisch",
      "Famous Painters": "Beroemde Schilders",
      "Iconic photos": "Iconische foto's",
      "Studio Collections": "Studio Collecties",
      "Modern artists": "Moderne kunstenaars",
      "Abstract art": "Abstracte kunst",
      "printsWithPassion": "Afdrukken met Passie",
      "handcraftedArt": "Ambachtelijke kunst, mooi en uniek",
      "Art Lovers Also Bought": "Kunstliefhebbers hebben ook gekocht",
      "Popular Picks for You": "Populaire keuzes voor jou",
      "From the Ciseco blog": "Van de Ciseco-blog",
      "The latest news": "Het laatste nieuws",
      "Show all blog articles": "Alle blogartikelen tonen",
      "Trending Art Pieces": "Trending Kunstwerken",
      "All Items": "Alle Items",
      "Vintage and Retro": "Vintage en Retro",
      "Filter": "Filter",
      "Discover the most popular prints in our collection.": "Ontdek Onze Collectie en Vind de Meest Populaire Afdrukken en Bestsellers.",
      "Quick view": "Snel bekijken",
      "Start exploring": "Begin met verkennen",
      "See Prints": "Zie Afdrukken",
      "products": "producten",
      "Visit Us": "Bezoek Ons",
      "in Den Bosch": "in Den Bosch",
      "Experience our craftsmanship firsthand. Pick up your prints, enjoy a coffee, and see where the magic happens": "Ervaar ons vakmanschap van dichtbij. Haal uw afdrukken op, geniet van een kopje koffie en zie waar de magie gebeurt",
      "Learn More": "Meer Leren",
      "Plan Your Visit": "Plan Uw Bezoek",
      "Sort order": "Sorteervolgorde",
      "Categories": "Categorieën",
      "Clear": "Wissen",
      "Apply": "Toepassen",
      "Min price": "Min prijs",
      "Max price": "Max prijs",
      "Products filters": "Productfilters",
      "On sale": "In de uitverkoop",
      "Products currently on sale": "Producten momenteel in de uitverkoop",
      "Show me more": "Laat meer zien",
      "Show me all": "Laat alles zien",
      "Browse & Select": "Bladeren & Selecteren",
      "Add to Cart": "Toevoegen aan Winkelwagen",
      "Fast Shipping": "Snelle Verzending",
      "Enjoy Your Art": "Geniet van Uw Kunst",
      "Shop Stunning Prints & Frames for Your Home Decor.": "Winkel Prachtige Afdrukken & Lijsten voor Uw Huisdecor.",
      "Custom Photo Prints & Framed Art": "Aangepaste Fotoprints & Ingelijste Kunst",
      "Learn About Giclée Printing": "Leer Over Giclée Afdrukken",
      "Upload, Customize, and Order Your Own Design.": "Upload, Pas aan, en Bestel Uw Eigen Ontwerp.",
      "Superior Quality Prints with Archival Inks & Paper.": "Superieure Kwaliteitsafdrukken met Archiefinkten & Papier.",
      "Affordable High-Quality Art Prints": "Betaalbare Hoge Kwaliteit Kunstdrukken",
      "Easily explore our wide range of art prints and find your perfect piece": "Verken gemakkelijk ons brede assortiment kunstafdrukken en vind uw perfecte stuk",
      "Choose your print, frame, and paper options, then add to your cart with a click": "Kies uw afdruk, lijst en papiersoorten, en voeg ze met een klik toe aan uw winkelwagen",
      "We carefully pack and ship your order within two days, ensuring it arrives quickly and safely": "We verpakken en verzenden uw bestelling zorgvuldig binnen twee dagen, zodat deze snel en veilig aankomt",
      "Unwrap and enjoy your beautifully crafted art print, made with love and care": "Pak uit en geniet van uw prachtig vervaardigde kunstafdruk, gemaakt met liefde en zorg",
      "Expert Giclée Printing Service": "Expert Giclée Printservice",
      "Explore Art Prints": "Verken Kunstafdrukken",
      "Create Your Custom Print": "Maak Uw Aangepaste Afdruk",
      "Quality You Can Feel": "Kwaliteit die je kunt voelen",
      "Diverse Art Styles": "Diverse Kunststijlen",
      "Visit Our Space": "Bezoek Onze Ruimte",
      "Exclusive Discounts": "Exclusieve Kortingen",
      "Step": "Stap",
      "Choose Your Perfect Frame": "Kies uw perfecte lijst",
      "Explore our wide range of frames to find the perfect match for your paintings and photos. Our high-quality framing options ensure durability and style, enhancing the presentation of your artwork. Select from various styles and finishes to suit your preferences and decor.": "Ontdek ons brede assortiment lijsten om de perfecte match te vinden voor uw schilderijen en foto's. Onze hoogwaardige inlijstopties zorgen voor duurzaamheid en stijl, waardoor de presentatie van uw kunstwerk wordt verbeterd. Kies uit verschillende stijlen en afwerkingen om aan uw voorkeuren en decor te voldoen.",
      "Explore Our Exquisite Frame Collection": "Ontdek onze prachtige lijstencollectie",
      "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints": "PrintMeArt is opgericht door onze gepassioneerde familie, toegewijd aan het tot leven brengen van uw dierbare herinneringen door middel van prachtige, hoogwaardige afdrukken",
      "Our Frames": "Onze Lijsten",
      "Technical Specifications": "Technische specificaties",
      "The walnut wood card tray is precision milled to perfectly fit a stack of Focus cards. The powder coated steel divider separates active cards from new ones, or can be used to archive important task lists.": "De walnoothouten kaartlade is nauwkeurig gefreesd om perfect te passen bij een stapel Focus-kaarten. De met poeder gecoate stalen verdeler scheidt actieve kaarten van nieuwe, of kan worden gebruikt om belangrijke takenlijsten te archiveren.",
      "Material": "Materiaal",
      "Premium wood base with a smooth matte finish.": "Premium houten basis met een gladde matte afwerking.",
      "Dimensions": "Afmetingen",
      "Available in multiple sizes to fit your artwork perfectly.": "Verkrijgbaar in meerdere maten om perfect bij uw kunstwerk te passen.",
      "Finish": "Afwerking",
      "Hand-finished with a durable, scratch-resistant coating.": "Met de hand afgewerkt met een duurzame, krasbestendige coating.",
      "Includes": "Bevat",
      "Frame, hanging hardware, and protective glass cover.": "Lijst, ophangmateriaal en beschermende glazen afdekking.",
      "Considerations": "Overwegingen",
      "Final Stock.": "Laatste voorraad.",
      "Up to 50% off.": "Tot 50% korting.",
      "Go to sales": "Ga naar de verkoop",
      "Welcome to PrintMeArt! We're a family-run business turning your favorite memories and artworks into stunning prints. Using top-notch Giclée printing, we ensure vibrant colors and fine detail in every piece. We believe in the magic of art to brighten your space. With a personal touch and genuine care, we offer a wide range of printing and framing options to suit your style. Join our PrintMeArt family and let us help you create something special. Your masterpiece awaits!": "Welkom bij PrintMeArt! We zijn een familiebedrijf dat uw favoriete herinneringen en kunstwerken omzet in prachtige afdrukken. Met behulp van hoogwaardig Giclée-drukwerk zorgen we voor levendige kleuren en fijne details in elk stuk. Wij geloven in de magie van kunst om uw ruimte op te fleuren. Met een persoonlijke touch en oprechte zorg bieden wij een breed scala aan print- en inlijstmogelijkheden om aan uw stijl te voldoen. Sluit u aan bij onze PrintMeArt-familie en laat ons u helpen iets speciaals te creëren. Uw meesterwerk wacht op u!",
      "About Us.": "Over Ons.",
      "Founders": "Oprichters",
      "PrintMeArt was founded by our passionate family, dedicated to bringing your cherished memories to life through beautiful, high-quality prints.": "PrintMeArt is opgericht door onze gepassioneerde familie, toegewijd aan het tot leven brengen van uw dierbare herinneringen door middel van prachtige, hoogwaardige afdrukken.",
      "Co-founder and Chief Executive": "Medeoprichter en Chief Executive",
      "Good news from far away": "Goed nieuws van ver weg",
      "Let's see what people think of PrintMeArt": "Laten we eens kijken wat mensen van PrintMeArt vinden",
      "Fast Facts": "Snelle Feiten",
      "We’re impartial and independent, and every day we create distinctive, world-class programmes and content": "We zijn onpartijdig en onafhankelijk, en elke dag creëren we onderscheidende, wereldklasse programma's en inhoud",
      "High-Quality Prints": "Hoogwaardige Afdrukken",
      "Crafted with care and top-notch Giclée technology": "Met zorg vervaardigd en top-notch Giclée-technologie",
      "Engineers Turned Artists": "Ingenieurs werden kunstenaars",
      "Family-owned business led by two passionate engineers": "Familiebedrijf geleid door twee gepassioneerde ingenieurs",
      "Custom Options": "Aangepaste Opties",
      "Choose from a variety of sizes, frames, and finishes": "Kies uit een verscheidenheid aan maten, lijsten en afwerkingen",
      "Don't miss out on special offers": "Mis geen speciale aanbiedingen",
      "Register to receive news about our latest deals, exclusive offers, and discount codes!": "Registreer om nieuws te ontvangen over onze nieuwste deals, exclusieve aanbiedingen en kortingscodes!",
      "Free Shipping": "Gratis Verzending",
      "Premium Quality": "Premium Kwaliteit",
      "Enter your email": "Voer uw e-mailadres in",
      "Navigation": "Navigatie",
      "Nature & Art": "Natuur & Kunst",
      "Experience our<br /> premium Giclée<br /> prints": "Ervaar onze<br /> premium Giclée<br /> afdrukken",
      "Vintage, <br />modern, nature,<br />abstract prints.": "Vintage, <br />modern, natuur,<br />abstracte afdrukken.",
      "Pick up prints,<br />enjoy coffee.": "Haal afdrukken op,<br />geniet van koffie.",
      "Up to <br /> 80% off selected art": "Tot <br /> 80% korting op geselecteerde kunst",
      "Upload your design, We Print": "Upload uw ontwerp, Wij Printen & Lijsten",
      "Upload your image,<br /> get the perfect print delivered.": "Upload uw afbeelding,<br /> krijg de perfecte afdruk geleverd.",
      "Type your keywords": "Typ uw zoekwoorden",
      "SOCIALS": "SOCIALS",
      "Full name": "Volledige naam",
      "Email address": "E-mailadres",
      "Message": "Bericht",
      "🗺 ADDRESS": "🗺 ADRES",
      "☎ PHONE": "☎ TELEFOON",
      "Example Doe": "Voorbeeld Doe",
      "example@example.com": "voorbeeld@voorbeeld.com",
      "Send Message": "Bericht Verzenden",
      "Log in": "Inloggen",
      "Shopping cart": "Winkelwagen",
      "Subtotal": "Subtotaal",
      "Shipping and taxes calculated at checkout.": "Verzendkosten en belastingen worden berekend bij het afrekenen.",
      "View cart": "Winkelwagen",
      "Check out": "Afrekenen",
      "Shopping Cart": "Winkelwagen",
      "Clothing Categories": "Kledingcategorieën",
      "Homepage": "Startpagina",
      "Order Summary": "Besteloverzicht",
      "Shipping estimate": "Verzendingschatting",
      "Tax estimate": "Belastingschatting",
      "Confirm order": "Bevestig bestelling",
      "Order total": "Bestelling totaal",
      "Discount code": "Kortingscode",
      "Learn more": "Meer informatie",
      "Taxes": "Belastingen",
      " and ": " en ",
      "Shipping ": "Verzending ",
      "infomation": "informatie",
      "CONTACT INFO": "CONTACTINFORMATIE",
      "SHIPPING ADDRESS": "VERZENDADRES",
      "Update account": "Account bijwerken",
      "Change Image": "Afbeelding wijzigen",
      "Account infomation": "Accountinformatie",
      "Change": "Veranderen",
      "First name": "Voornaam",
      "Last name": "Achternaam",
      "Apt, Suite *": "Appartement, Suite *",
      "City": "Stad",
      "Country": "Land",
      "State/Province": "Staat/Provincie",
      "Postal code": "Postcode",
      "Address type": "Adres type",
      "Home": "Thuis",
      "All Day Delivery": "Hele dag levering",
      "Office": "Kantoor",
      "Delivery": "Levering",
      "Save and next to Payment": "Opslaan en doorgaan naar Betaling",
      "Cancel": "Annuleren",
      "PAYMENT METHOD": "BETAALMETHODE",
      "Google / Apple Wallet": "Google / Apple Wallet",
      "Type and press enter": "Typ en druk op enter",
      "Landscapes": "Landschappen",
      "Movies & Games posters": "Films & Spel posters",
      "Music posters": "Muziek posters",
      "Sport posters": "Sport posters",
      "General posters": "Algemene posters",
      "Kids' wall art": "Kinderwandkunst",
      "Dutch masters": "Nederlandse meesters",
      "Modern masters": "Moderne meesters",
      "Nature & Landscapes": "Natuur & Landschappen",
      "From": "Vanaf",
      "Turn Your Photos into Art": "Verander je foto's in kunst",
      "Upload your favorite image, customize your design, and let our designer ensure a stunning framed print, approved by you before we print.": "Upload je favoriete afbeelding, pas je ontwerp aan, en laat onze ontwerper zorgen voor een verbluffende ingelijste print, goedgekeurd door jou voordat we het afdrukken.",
      "Upload Your Image": "Upload je afbeelding",
      "Simply upload your favorite photo or artwork. We accept PNG, JPG, and GIF files up to 10MB.": "Upload eenvoudig je favoriete foto of kunstwerk. We accepteren PNG-, JPG- en GIF-bestanden tot 10MB.",
      "Customize Your Design": "Pas je ontwerp aan",
      "Choose from a variety of frames, sizes, and mats. Visualize your design instantly before finalizing your order.": "Kies uit verschillende lijsten, maten en passe-partouts. Visualiseer je ontwerp direct voordat je je bestelling afrondt.",
      "Professional Review": "Professionele beoordeling",
      "Our expert designer will review your design, make any necessary adjustments, and send it to you for approval before printing.": "Onze ervaren ontwerper beoordeelt je ontwerp, maakt de nodige aanpassingen en stuurt het naar je ter goedkeuring voordat we gaan afdrukken.",
      "Order with Confidence": "Bestel met vertrouwen",
      "Complete your purchase with our secure checkout. Your custom art will be professionally printed and delivered to your door.": "Rond je aankoop af via onze veilige betaalomgeving. Je aangepaste kunstwerk wordt professioneel afgedrukt en bij je thuis bezorgd.",
      "Upload a file": "Upload een bestand",
      "or drag and drop" : "of sleep en zet neer",
      "Selected file size": "Geselecteerde bestandsgrootte",
      "Total": "Totaal",
      "Include Mat": "Inclusief Mat",
      "Image Size": "Afbeeldingsgrootte",
      "See sizing chart": "Zie maattabel",
      "See frame details": "Zie lijstdetails",
      "We Print & Frame": "Wij Printen & Lijsten",
      "Gallery-Quality Art & Photography": "Kunst & Fotografie van Galerijkwaliteit",
      "We Print": "Wij Printen",
      "Elevate your home with museum-grade...": "Transformeer je huis met museumwaardige kunstafdrukken en opvallende fotografie. Onze zorgvuldig samengestelde collectie biedt levendige kleuren en fijne details. Ontdek mooie posters, elegante afdrukken en betoverende foto’s, allemaal met op maat gemaakte lijstopties om elke ruimte te verfraaien tegen betaalbare prijzen.",
      "Discover our best-selling art prints, posters, and photos. Find top picks that art enthusiasts and decor lovers adore for every space": "Ontdek onze best verkochte kunstafdrukken, posters en foto's. Vind topkeuzes die kunstliefhebbers en decorliefhebbers aanbidden voor elke ruimte",
      "High-Quality Art Prints, Posters, Custom Framing, and Stunning Photography for Your Home": "Hoogwaardige kunstafdrukken, posters, aangepaste lijsten en prachtige fotografie voor uw huis",
      "About us": "Over ons",
      "Printed Art, Posters, and Photos": "Gedrukte kunst, posters en foto's",
      "Print Quality & Care": "Afdrukkwaliteit & Zorg",
      "Sympathize with Our Services": "Leef mee met onze diensten",
      "Vibrant Colors & Versatile Sizes": "Levendige kleuren & veelzijdige maten",
      "FAQ": "FAQ",
      "Preview Your Design": "Voorbeeld van uw ontwerp",
      "Product details": "Productdetails",
      "Free shipping": "Gratis verzending",
      "Free shipping on all orders above €50 within the Netherlands.": "Gratis verzending op alle bestellingen boven €50 binnen Nederland.",
      "Nationwide Delivery": "Landelijke Levering",
      "Fast delivery nationwide.": "Snelle levering in heel Nederland over 2 dagen.",
      "Very easy to return": "Zeer gemakkelijk te retourneren",
      "Just phone number.": "Alleen telefoonnummer.",
      "Refunds policy": "Restitutiebeleid",
      "30 days return for any reason": "30 dagen retourneren om welke reden dan ook",
      "Upload now": "Upload nu",
      "Perfect Fit Guarantee": "Perfecte Pasvorm Garantie",
      "Our professional designers ensure every image, even horizontal ones, is perfectly tailored to fit your frame. We'll send you the final design for your approval, giving you the last say.": "Onze professionele ontwerpers zorgen ervoor dat elke afbeelding, zelfs horizontale, perfect op maat wordt gemaakt voor uw lijst. We sturen u het definitieve ontwerp ter goedkeuring, zodat u het laatste woord heeft.",
      "Print Your Design": "Print Uw Ontwerp",
      "Bring Your Vision to Life with High-Quality Custom Prints": "Breng Uw Visie tot Leven met Hoogwaardige Aangepaste Afdrukken",
      "Turn your photos and designs into stunning, high-quality custom art prints that elevate any space. Whether you’re looking for personalized wall art, custom posters, or framed prints, we deliver exceptional results across the Netherlands, Germany, and all of Europe.": "Verander uw foto's en ontwerpen in prachtige, hoogwaardige aangepaste kunstafdrukken die elke ruimte verheffen. Of u nu op zoek bent naar gepersonaliseerde wandkunst, aangepaste posters of ingelijste afdrukken, wij leveren uitzonderlijke resultaten in heel Nederland, Duitsland en heel Europa.",
      "Premium Quality Custom Posters": "Hoogwaardige Aangepaste Posters",
      "From personal photos to unique artwork, our custom art prints are crafted with care, ensuring vibrant colors and long-lasting quality. Perfect for European homes, our prints bring your vision to life.": "Van persoonlijke foto's tot unieke kunstwerken, onze aangepaste kunstafdrukken zijn met zorg vervaardigd, met levendige kleuren en een langdurige kwaliteit. Perfect voor Europese huizen, onze afdrukken brengen uw visie tot leven.",
      "Personalized Framed Prints": "Gepersonaliseerde Ingelijste Afdrukken",
      "Choose from a variety of elegant poster frames, sizes, and mats to create a custom framed print that fits your style. Each print is delivered ready to hang, making it easy to transform your space.": "Kies uit een verscheidenheid aan elegante posterlijsten, maten en passe-partouts om een aangepaste ingelijste afdruk te maken die bij uw stijl past. Elke afdruk wordt geleverd klaar om op te hangen, waardoor het gemakkelijk is om uw ruimte te transformeren.",
      "Fast & Reliable European Shipping": "Snelle & Betrouwbare Europese Verzending",
      "We understand the importance of speed. That’s why we offer fast delivery custom photo prints across Europe, including the Netherlands, ensuring your artwork arrives quickly and in perfect condition.": "We begrijpen het belang van snelheid. Daarom bieden wij snelle levering van aangepaste fotoprints in heel Europa, inclusief Nederland, zodat uw kunstwerk snel en in perfecte staat aankomt.",
      "Upload and Print Your Own Designs": "Upload en Print Uw Eigen Ontwerpen",
      "Simply upload your design or photo, and we’ll handle the rest. Our expert team ensures that your high-quality custom poster is printed and framed to perfection, with fast shipping guaranteed.": "Upload eenvoudig uw ontwerp of foto, en wij regelen de rest. Ons deskundige team zorgt ervoor dat uw hoogwaardige aangepaste poster perfect wordt afgedrukt en ingelijst, met gegarandeerde snelle verzending.",
      "From Amsterdam to Berlin, our European poster printing service ensures that your custom art prints and personalized gifts are delivered with Dutch quality and reliability. Order today and experience the difference!": "Van Amsterdam tot Berlijn, onze Europese posterprintservice zorgt ervoor dat uw aangepaste kunstafdrukken en gepersonaliseerde geschenken worden geleverd met Nederlandse kwaliteit en betrouwbaarheid. Bestel vandaag en ervaar het verschil!",
      "Design Your Poster - Art - Photo": "Ontwerp Uw Poster - Kunst - Foto",
      "Elevate Your Space with High-Quality Custom Prints and Art": "Verfraai Uw Ruimte met Hoogwaardige Aangepaste Prints en Kunst",
      "Welcome to PrintMeArt, your premier destination for high-quality prints, custom posters, and art prints. Whether you want to enhance your home with stunning art posters from our catalog, or showcase your creativity by uploading and printing your own designs, we’ve got everything you need.": "Welkom bij PrintMeArt, uw toonaangevende bestemming voor hoogwaardige afdrukken, op maat gemaakte posters, en kunstafdrukken. Of u nu uw huis wilt verfraaien met onze prachtige kunstposters uit de catalogus, of uw creativiteit wilt tonen door uw eigen ontwerpen te uploaden en te printen, wij hebben alles wat u nodig hebt.",
      "Wide Selection of Art & Posters": "Breed Assortiment aan Kunst & Posters",
      "Explore our extensive collection of art prints, custom posters, and photography. From modern designs to classic pieces, our catalog offers something for everyone.": "Ontdek onze uitgebreide collectie kunstafdrukken, op maat gemaakte posters, en fotografie. Van moderne ontwerpen tot klassieke stukken, onze catalogus biedt voor ieder wat wils.",
      "Have a unique vision? Easily upload your own designs, photos, or artwork, and we’ll transform them into beautiful prints. Perfect for personalizing your space or creating unique personalized gifts in the Netherlands.": "Heb je een unieke visie? Upload eenvoudig je eigen ontwerpen, foto's of kunstwerken, en wij transformeren ze in prachtige afdrukken. Perfect voor het personaliseren van je ruimte of het creëren van unieke gepersonaliseerde cadeaus in Nederland.",
      "Our prints are made using the finest materials, ensuring vibrant colors and sharp details. Whether you’re decorating your home or surprising a loved one, our high-quality posters are designed to impress.": "Onze afdrukken worden gemaakt met de beste materialen, waardoor levendige kleuren en scherpe details gegarandeerd zijn. Of u nu uw huis decoreert of een geliefde verrast, onze hoogwaardige posters zijn ontworpen om te imponeren.",
      "Custom Print Posters and Photo Printing": "Op Maat Gemaakte Printposters en Fotobedrukking",
      "Complete your artwork with our stylish framing options, available in various sizes and finishes. Our frames are designed to complement your prints, making them ready to hang and adding elegance to any room.": "Voltooi uw kunstwerk met onze stijlvolle inlijstopties, beschikbaar in verschillende formaten en afwerkingen. Onze lijsten zijn ontworpen om uw afdrukken te complementeren, waardoor ze klaar zijn om op te hangen en elegantie toevoegen aan elke kamer."
    }
  }
}

;