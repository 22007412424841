import { Product, ProductVariant } from "../models/ProductModels";
import { Frame } from "../models/ProductModels";


export const DEMO_VARIANTS: ProductVariant[] = [
 
];

export const PRODUCTS: Product[] = [
  {
    name: "Product 1",

  } as Product
];
export const FRAMES: Frame[] = [
  {
    id: 1,
    name: `Black Frame | EDSBRUK`,
    description: "Decorate with pictures you love. This traditional, robust frame has a soft profile and comes in many sizes, perfect for a picture wall. The plastic front protection is safe ― and does justice to the motif.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0723741_pe734158_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame8-edsbruk-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame8-edsbruk.png",
    image:
      "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0723741_pe734158_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0723740_pe734159_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0723739_pe734160_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0723741_pe734158_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__1009335_ph176840_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__0747489_pe744559_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/edsbruk-frame-black-stained__1203360_pe906293_s5.jpg?f=s"],
  
  },
  {
    id: 2,
    name: `white stained pine effect | PLOMMONTRÄD`,
    description: "The pattern of PLOMMONTRÄD frame has small variations, making each frame unique – and the slightly wider dimensions create a robust and traditional expression. Choose between 3 sizes, or combine them all.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1202413_pe905936_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame6-plommon-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame6-plommon.png",
    image:
      "https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1202413_pe905936_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1202411_pe905935_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1202412_pe905937_s5.jpg?f=xl","https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1202413_pe905936_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1274126_ph194587_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/plommontrad-frame-white-stained-pine-effect__1203594_pe906362_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__1203360_pe906293_s5.jpg?f=s"],
  },
  {
    id: 3,
    name: `White Frame | EDSBRUK`,
    description: "Decorate with pictures you love. This traditional, robust frame has a soft profile and comes in many sizes, perfect for a picture wall. The plastic front protection is safe ― and does justice to the motif.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0706506_pe725889_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame5-edsbruk-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame5-edsbruk.png",
    image:
      "https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0706506_pe725889_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0706504_pe725890_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0902092_pe725882_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0706506_pe725889_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0939333_ph160793_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__0767914_pe754344_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__1203360_pe906293_s5.jpg?f=s" ],
  },
  {
    id: 4,
    name: `Brown Frame | RAMSBORG`,
    description: "Sustainable beauty from sustainably-sourced solid wood, a durable and renewable material that maintains its genuine character with each passing year.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0726700_pe735389_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame7-ramsborg-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame7-ramsborg.png",
    image:
      "https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0726700_pe735389_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0726699_pe735390_s5.jpg?f=xl","https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0726698_pe735391_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0726700_pe735389_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__1350957_pe951835_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/ramsborg-frame-brown__0919777_ph164133_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/edsbruk-frame-white__1203360_pe906293_s5.jpg?f=s"],
  },
  {
    id: 5,
    name: `Black Frame | RÖDALM`,
    description: "RÖDALM frame has a modern look that does your favourite motifs justice. Place the picture in the front or back of the frame.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1251233_pe924195_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame1-rodalm-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame1-rodalm.png",
    image: "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1251233_pe924195_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1251232_pe924196_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1298269_pe936170_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1251233_pe924195_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1298276_pe936177_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1298259_pe936159_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/rodalm-frame-black__1330544_pe945716_s5.jpg?f=s"],
  },
  {
    id: 6,
    name: `Black Frame | KNOPPANG`,
    description: "Decorate with pictures you love. This frame has a thin edge with a profile that has a traditional look and comes in many sizes. The plastic front protection is safe ― and does justice to the motif.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0638249_pe698799_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame2-knoppang-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame2-knoppang.png",
    image:
      "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0638249_pe698799_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0902477_pe661084_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0902012_pe661072_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0638249_pe698799_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__1187224_pe899111_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__0939314_ph166069_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/knoppang-frame-black__1202391_pe905919_s5.jpg?f=s"],
  },
  {
    id: 7,
    name: `Gold Frame | SILVERHÖJDEN`,
    description: "Decorate with pictures you love. This frame has a matt metal-like finish and comes in many sizes, perfect for a picture wall. The plastic front protection is safe ― and does justice to the motif.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1179571_pe895993_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame4-silverhojden-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame4-silverhojden.png",
    image:
      "https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1179571_pe895993_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1179570_pe895994_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1179568_pe895991_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1179571_pe895993_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1187227_pe899114_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1350950_pe951830_s5.jpg?f=xl","https://www.ikea.com/nl/en/images/products/silverhojden-frame-gold-colour__1202816_pe906101_s5.jpg?f=s"],
  },
  {
    id: 8,
    name: `Gold Frame | LOMVIKEN`,
    description: "Decorate with pictures you love. This frame comes in many sizes and has a thin metal edge with a profile that creates a shadow. The plastic front protection is safe ― and does justice to the motif.",
    thumbnail: "https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0661072_pe711302_s5.jpg?f=xxs",
    price: 6,
    mask: 'https://genstorageaccount3116.blob.core.windows.net/printme-images/frame3-lomviken-mat.png',
    maskWithoutMat: "https://genstorageaccount3116.blob.core.windows.net/printme-images/frame3-lomviken.png",
    image:
      "https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0661072_pe711302_s5.jpg?f=s",
    allImages: ["https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0661070_pe711317_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0902049_pe731149_s5.jpg?f=s","https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0661072_pe711302_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__0661071_pe711318_s5.jpg?f=s", "https://www.ikea.com/nl/en/images/products/lomviken-frame-gold-colour__1171372_pe893053_s5.jpg?f=s"],
  },
];
